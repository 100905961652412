<template>
  <div class="detail-maxbox">
    <div class="detail-minbox">
      <div class="detail-title">{{listData.title}}</div>
      <div class="detail-time">{{listData.created_time | secondFormat}}</div>
<!--      <img :src="listData.img_url"/>-->
      <div class="detail-content" v-html="listData.content"></div>
    </div>
    <div class="next-minbox">
      <div class="next-left">
        <div @click="handleTo(0)" :title="title">上一篇：{{ title ? title : '暂无'}}</div>
        <div @click="handleTo(1)" :title="nextTitle">下一篇：{{ nextTitle ? nextTitle : '暂无'}}</div>
      </div>
      <div class="next-right" @click="handleBack"> 返回 </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  // beforeRouteEnter (to, from, next) {
  //   next (vm => {
  //     vm.$router.replace({path: from.path, query: to.query})
  //   })
  // },
  data () {
    return{
      parms: {
        id: 0
      },
      from:{
        start: 0,
        limit: 1000
      },
      index: 0,
      title: "",
      src: "",
      nextSrc: "",
      nextTitle: "",
      listData:{},
      nextList: []
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.parms.id = this.$route.query.id
      this.index = this.$route.query.index
    }
    this.$log({
      type: 2,
      topic: "ymp.infomation",
      id: "ymp.infomationdetail",
    });
    this.postList(this.from);
    this.postList(this.parms);
  },
  methods:{
    //运抵达资讯接口
    async postList(datas) {
      let data = await this.$store.dispatch("API_freight/postList", datas);
      if (datas == this.parms) {
        this.listData = data.data
      } else {
        this.nextList = data.data;
        this.nextList.forEach((item, index) => {
          // console.log(item.id);
          console.log(index);
          if ((index+1) == this.index) {
            this.nextTitle = item.title
            this.nextSrc = '/infoDetail?id=' + item.id + '&index=' + index
          } else if ((index-1) == this.index){
            this.title = item.title
            this.src = '/infoDetail?id=' + item.id + '&index=' + index
          }
        })
      }
    },
    handleTo (index) {
      if (index == 0) {
        if (this.src) {
          let routeData = this.$router.resolve({
            path: this.src
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        if (this.nextSrc) {
          let routeData = this.$router.resolve({
            path: this.nextSrc
          });
          window.open(routeData.href, "_blank");
        }
      }
    },
    handleBack () {
      this.$router.push({
        path: '/infoList'
      });
    }
  }
}
</script>

<style lang="less" scoped>
.detail-maxbox{
  width: 100%;
  height: 100vh;
  background: #FBFCFA;
  padding: 32px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  .detail-minbox{
    width: 1300px;
    margin: 0 auto;
    background: #FFFFFF;
    padding: 48px 0 64px;
    box-sizing: border-box;
    text-align: center;
    .detail-title{
      font-size: 36px;
      font-weight: bold;
      color: #3C5058;
    }
    .detail-time{
      font-size: 16px;
      font-weight: 400;
      color: #3C5058;
      padding: 32px 0;
      box-sizing: border-box;
    }
    .detail-content{
      font-size: 16px;
      font-weight: 400;
      color: #3C5058;
      margin-top: 16px;
    }
  }
  .next-minbox{
    width: 1300px;
    margin: 24px auto 0;
    display: flex;
    align-items: first;
    justify-content: space-between;
    .next-left{
      div{
        width: 300px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-bottom: 16px;
        font-size: 14px!important;
        font-weight: 400!important;
        color: #0C263F!important;
        cursor: pointer;
      }
    }
    .next-right{
      font-size: 14px;
      font-weight: 400;
      color: #006EDF;
      cursor: pointer;
    }
  }
}
</style>